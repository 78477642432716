.spinner {
  opacity: 0;
  transition: opacity .2s ease;
}

.spinner--show {
   opacity: 1;
}

.spinner__loader {
  display: inline-block;
  vertical-align: middle;

  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9;
  transform: translate(-50%, -50%);
}

.spinner__loader:before {
   content: '';
   display: block;
   padding-top: 100%;
}

.spinner__svg {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.spinner__svg > circle {
  stroke-dasharray: 1,200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-width: 2;
  stroke-miterlimit: 10;
  fill: none;
  animation: dash 1.5s ease-in-out infinite;
  stroke: currentColor;
}

@-webkit-keyframes rotate {
  100% {
    transform: rotate(360deg)
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg)
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0
  }

  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35px
  }

  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124px
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0
  }

  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35px
  }

  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124px
  }
}
